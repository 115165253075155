import request from '@/utils/request'

// 查询待还款记录列表
export function getUnsetlLoanList(data) {
  return request({
    method: 'get',
    url: `/cls/core/app/coreRepay/getUnsetlLoanList/${data.uid}`,
    params: data,
    // /cls/core/app/coreRepay/getUnsetlLoanList/{userId}
  })
}
// 查询还款计划
export function getRepayPlanListApi(loanNo) {
  return request({
    method: 'get',
    url: `/cls/core/app/coreRepay/getRepayPlanList/${loanNo}`,
    data: {},
  })
}
// 查询还款金额
export function getRepaySumAmtInfoApi(data) {
  return request({
    method: 'get',
    url: `/cls/core/app/coreRepay/getRepaySumAmtInfo/${data.userId}`,
    params: data.data,
  })
}
// 获取转账还款信息
export function getTranRepayInfoApi(loanNo) {
  return request({
    method: 'get',
    url: `/cls/core/app/coreRepay/getTranRepayInfo/${loanNo}`,
    data: {},
  })
}

// 线上主动还款试算
export function getActiverepayTrialApi(data) {
  return request({
    method: 'post',
    url: 'cls/core/app/active/repayTrial',
    data,
  })
}

// 线上主动还款-提交还款
export function getActiverepayRepayApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/activeRepay/repay',
    data,
  })
}

// 查询用户银行卡列表
export function getUserBindCardListApi(data) {
  return request({
    method: 'post',
    params:data,
    url: '/cls/passport-personal/app/user/info/bindCardList',
    // data: {},
  })
}

// 借款记录列表
export function getLoanListApi(data) {
  return request({
    method: 'get',
    url: `/cls/core/app/coreRepay/getApplList/${data.uid}`,
    params: data,
  })
}

// 发送还款短信
export function sendRepayCodeApi(loanNo) {
  return request({
    method: 'post',
    url: `/cls/core/app/activeRepay/sendRepayCode/${loanNo}`,
    data: {},
  })
}

// 还款短信验证
export function repayVerifyCodeApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/activeRepay/repayVerifyCode/${data.loanNo}`,
    params: data,
  })
}

// 获取合同编码
export function getTempCodeApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/product/getTempCode`,
    data: data,
  })
}

// 合同预览
export function viewAfterSignPdfApi(data) {
  return request({
    method: 'get',
    url: `/cls/contract/app/contract/file/viewAfterSignPdf`,
    params: data,
    responseType: 'blob',
  })
}

// 银行卡限额
export function bankLimitApi(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/common/bankLimit`,
    data: data,
  })
}
