<template>
  <div class="repayment-container">
    <div class="repayment-content" v-if="showPage">
      <div class="rem30 lh21">
        {{ text }}
      </div>
      <div class="rem56 lh40 mt6 f500">￥{{ myLoan.instSumAmt }}</div>
      <div class="c-4D7BFE rem30 lh21 mt6" @click="checkDetail">查看付款计划</div>

      <div class="flex just-between mt30">
        <div>融资金额</div>
        <div>
          ￥<span>{{ myLoan.origPrcp }}</span>
        </div>
      </div>
      <div class="flex just-between mt20">
        <div>融资状态</div>
        <div>{{ myLoan.odInd }}</div>
      </div>
      <div class="flex just-between mt20">
        <div>借据编号</div>
        <div>{{ myLoan.loanNo }}</div>
      </div>
      <div class="flex just-between mt20">
        <div>融资期限</div>
        <div>
          <span>{{ myLoan.tnr }}</span> 个月
        </div>
      </div>
      <div class="flex just-between mt20">
        <div>自动付款账户</div>
        <div @click="changeCard">
          <span>{{ bankInfo.bankName }}({{ bankCard }})</span>
          <span class="c-4D7BFE">修改</span>
        </div>
      </div>

      <!-- 折叠内容 -->
      <div v-show="see">
        <div class="flex just-between mt20">
          <div>融资人姓名</div>
          <div>{{ myLoan.name }}</div>
        </div>
        <div class="flex just-between mt20">
          <div>融资人身份证</div>
          <div>{{ myLoan.maskIdNo }}</div>
        </div>
        <!-- <div class="flex just-between mt20">
          <div>收款账户</div>
          <div>中国工商银行(4301)</div>
        </div> -->
        <div class="flex just-between mt20">
          <div>起止日期</div>
          <div>{{ myLoan.intStartDt }}至{{ myLoan.lastDueDt }}</div>
        </div>
        <div class="flex just-between mt20">
          <div>融资合同</div>
          <div class="c-4D7BFE" @click="checkOut">查看</div>
        </div>
      </div>

      <van-divider />
      <div class="flex just-around">
        <div class="flex align-center c-4D7BFE" @click="seeMore" v-show="!see">
          更多信息 <van-icon name="arrow-down" />
        </div>

        <div class="flex align-center c-4D7BFE" @click="seeMore" v-show="see">
          收起信息 <van-icon name="arrow-up" />
        </div>
      </div>
    </div>
    <div class="repayment-btn">
      <van-button round block type="info" v-throttle="[payCurrent, `click`, 3000]">
        <span class="rem34">付本期</span>
      </van-button>
      <van-button
        round
        block
        plain
        color="#4D7BFE"
        class="btn"
        v-show="canPreRepay == 1"
        v-throttle="[prePay, `click`, 3000]"
      >
        <span class="rem34">提前付款</span>
      </van-button>
    </div>
    <repayment-plan ref="repayment" :myLoan="myLoan"></repayment-plan>
  </div>
</template>

<script>
import Vue from 'vue'
import { Divider, Icon, Button, Popup } from 'vant'
import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Divider)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Popup)

import repaymentPlan from '@/components/repayment-plan.vue'
import { getActiverepayTrialApi } from '@/apis/repayment/index'
import { Decrypt } from '@/utils/crypto'
//
export default {
  components: {
    repaymentPlan,
  },
  data() {
    return {
      see: false,
      loanNo: '', // 我的借款id
      myLoan: {}, // 我的借款
      setlTypeNum: '', //还款类型
      info: {},
      bankInfo: {},
      canPreRepay: '', //是否能提前还款
      remitAmt: '',
      arr: [],
      bankCardNo: '',
      showPage: false,
      text: '',
      bankCard: '',
    }
  },
  created() {
    this.myLoan = this.$store.state.repayment.loan
    this.text = this.myLoan.endDt
      ? `${this.myLoan.endDt.split('-')[1]} 月 ${this.myLoan.endDt.split('-')[2]}日应付`
      : ''
    this.bankInfo = this.$store.state.repayment.bankInfo
    if (JSON.stringify(this.bankInfo) != '{}' && this.bankInfo.bankCardNo) {
      this.bankCardNo = Decrypt(this.bankInfo.bankCardNo)
      this.bankCard = this.bankCardNo.substr(-4)
    }
    this.canPreRepay = this.$route.query.canPreRepay
    this.showPage = true
  },
  methods: {
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },

    checkDetail() {
      this.$refs.repayment.show = true
    },
    seeMore() {
      this.see = !this.see
    },
    changeCard() {
      this.$router.push({ path: '/changeBankCard', query: { type: 'changeCard1' } })
    },

    // 还本期
    payCurrent() {
      this.setlTypeNum = '1'
      this.getActiverepayTrial()
    },

    // 提前还款
    prePay() {
      this.setlTypeNum = '2'
      this.getActiverepayTrial()
    },

    // 主动还款试算
    getActiverepayTrial() {
      this.showloading()
      getActiverepayTrialApi({
        loanNo: this.myLoan.loanNo,
        setlType: this.setlTypeNum,
        calType: '0',
        setlOrg: 'ACTV',
      })
        .then((res) => {
          this.info = res.data
          this.$store.commit('payInfoFn', this.info)
          if (res.code === 200) {
            this.hideloading()
            this.remitAmt = res.data.remitAmt
            // console.log(res.data, this.remitAmt)
            this.$router.push({
              path: '/repaymentPay',
              query: { setlType: this.setlTypeNum, remitAmt: this.remitAmt },
            })
          } else {
            this.hideloading()
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },

    // 查看借款合同
    checkOut() {
      this.$router.push({
        path: '/repayContract',
        query: { loanNo: this.myLoan.loanNo },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.repayment-container {
  padding: 0.3rem /* 15/50 */ 0.4rem /* 20/50 */;
  .repayment-btn {
    margin-top: 2.22rem /* 111/50 */;
    .btn {
      margin-top: 0.4rem /* 20/50 */;
    }
  }
}
.img16 {
  width: 0.3rem;
}
</style>
