<template>
  <div>
    <!-- 还款--还款计划弹窗 -->
    <div v-if="show">
      <van-popup v-model="show" class="popup-content">
        <div class="flex just-end" @click="show = false">
          <img :src="closeIcon" class="img16" alt="" />
        </div>
        <div class="popup-title">付款计划</div>
        <div class="popup-head">
          <div class="paddinglr20">
            <div class="rem26 lh18">待付总额(元)</div>
            <div class="rem48 lh40">¥{{ borrowTotal }}</div>
          </div>
          <van-divider />
          <div class="flex just-around padding-bottom">
            <div class="text-center border width50">
              <div class="rem26 lh18">期限</div>
              <div class="rem30 lh21">{{ myLoan.tnr }}个月</div>
            </div>
            <div class="text-center width50">
              <div class="rem26 lh18">付款方式</div>
              <div class="rem30 lh21">{{ myLoan.paymMtd }}</div>
            </div>
          </div>
        </div>
        <div class="mt10">
          <div v-for="(item, index) in planList" :key="index" class="flex">
            <div class="c-999999 rem26" :class="item.lastSetYear ? 'repayment-year' : ''">
              <p>{{ item.lastSetYear }}</p>
              <div class="flex align-center">
                <p :class="item.lastSetYear ? '' : 'repayment-day'">{{ item.endDt.substr(-5) }}</p>
              </div>
            </div>
            <div class="content-border"></div>
            <div class="content repayment-desciption">
              <div class="rem30 c-303133">
                <span>{{ item.busName }}</span>
                <span class="repayment-type"></span>
                <span>{{ item.perdNo }}/{{ myLoan.tnr }}期</span>
                <span
                  class="repayment-status rem24"
                  :class="{ returned: item.odInd == '0', overdue: item.odInd == '1' }"
                  >{{
                    item.setlInd == '1' ? '已结清' : item.odInd == '1' ? '已逾期' : '待付款'
                  }}</span
                >
              </div>
              <p class="mt6 c-303133 rem40">¥{{ item.repayAmtSum }}</p>
              <p class="mt6 c-999999 rem26">本金{{ item.prcp }}+利息{{ item.normInt }}</p>
              <p class="mt6 c-999999 rem26">手续费{{ item.fee }}</p>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <!-- 借款--还款计划预览 -->
    <div v-if="borrowShow">
      <van-popup v-model="borrowShow" class="popup-content">
        <div class="flex just-end" @click="borrowShow = false">
          <img :src="closeIcon" class="img16" alt="" />
        </div>
        <div class="popup-title">付款计划</div>
        <div class="popup-head">
          <div class="paddinglr20">
            <div class="rem26 lh18">待付总额(元)</div>
            <div class="rem48 lh40">¥{{ repaymentLoan.totalAmt }}</div>
          </div>
          <van-divider />
          <div class="flex just-around padding-bottom">
            <div class="text-center border width50">
              <div class="rem26 lh18">融资周期</div>
              <div class="rem30 lh21">{{ repaymentLoan.totalApplyTnr }}个月</div>
            </div>
            <div class="border text-center width50">
              <div class="rem26 lh18">利息</div>
              <div class="rem30 lh21">¥{{ repaymentLoan.totalInt }}</div>
            </div>
            <div class="text-center width50">
              <div class="rem26 lh18">手续费</div>
              <div class="rem30 lh21">¥{{ repaymentLoan.totalFeeAmt }}</div>
            </div>
          </div>
        </div>
        <div class="mt10">
          <div v-for="(item, index) in repaymentList" :key="index" class="flex">
            <div class="c-999999 rem26" :class="item.lastSetYear ? 'repayment-year' : ''">
              <p>{{ item.lastSetYear }}</p>
              <div class="flex align-center">
                <p :class="item.lastSetYear ? '' : 'repayment-day'">{{ item.lastSetDay }}</p>
              </div>
            </div>
            <div class="content-border"></div>
            <div class="content repayment-desciption">
              <div class="rem30 c-303133 flex">
                <p>{{ busName }}</p>
                <p class="repayment-type">
                  第{{ item.perdNo }}/{{ repaymentLoan.totalApplyTnr }}期
                </p>
              </div>
              <p class="mt6 c-303133 rem40">¥{{ item.psInstmAmount }}</p>
              <p class="mt6 c-999999 rem26">本金{{ item.prcpAmt }}+利息{{ item.intAmt }}</p>
              <p class="mt6 c-999999 rem26">手续费{{ item.instmAmt }}</p>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { Popup, Divider } from 'vant'
import Vue from 'vue'
import { getUid } from '@/utils/auth'
import { mapState } from 'vuex'

Vue.use(Popup)
Vue.use(Divider)

import { getRepayPlanListApi, getRepaySumAmtInfoApi } from '@/apis/repayment/index'

export default {
  props: {
    myLoan: {
      type: Object,
    },
    repaymentLoan: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      borrowShow: false,
      closeIcon: require('@/assets/images/payment/close-icon.png'),
      planList: [], // 还款计划
      borrowTotal: 0,
      uid: '',
      busName: '', // 贷款类型
      repaymentList: [],
    }
  },
  created() {
    this.uid = getUid()
  },
  computed: {
    ...mapState({
      productCode: (state) => state.common.productCode,
    }),
  },
  methods: {
    getDetails(loanNo) {
      getRepayPlanListApi(loanNo).then((res) => {
        if (res.code === 200) {
          this.planList = res.data || []
          this.planList.forEach((item) => {
            if (item.setlInd == '0') {
              // 未结清
              if (item.endDt) {
                item.lastSetYear = item.endDt.substr(0, 4)
                item.lastSetDay = item.endDt.substr(5, 10)
              }
            } else if (item.setlInd == '1') {
              // 已结清
              if (item.lastSetlDt) {
                item.lastSetYear = item.lastSetlDt.substr(0, 4)
                item.lastSetDay = item.lastSetlDt.substr(5, 10)
              }
            }
          })
          let year = this.planList[0].lastSetYear
          this.planList.forEach((item, index) => {
            if (index != 0 && item.lastSetYear === year) {
              item.lastSetYear = ''
            } else {
              year = item.lastSetYear
            }
          })
        }
      })
    },
    getRepaySumAmtInfo() {
      getRepaySumAmtInfoApi({
        userId: this.uid,
        data: { getAllAmt: '1', loanNo: this.myLoan.loanNo },
      }).then((res) => {
        if (res.code === 200) {
          this.borrowTotal = res.data.instSumAmt
        }
      })
    },
  },

  watch: {
    show() {
      this.getDetails(this.myLoan.loanNo)
      this.getRepaySumAmtInfo()
    },
    borrowShow() {
      this.repaymentList = []
      if (this.productCode.indexOf('cgd') != -1) {
        this.busName = '采易融'
      } else if (this.productCode.indexOf('kdd') != -1) {
        this.busName = '顺丰快递贷'
      }
      let planList = []
      planList = this.repaymentLoan.shdList
      planList.forEach((item) => {
        if (item.dueDt) {
          item.lastSetYear = item.dueDt.substr(0, 4)
          item.lastSetDay = item.dueDt.substr(5, 10)
        }
      })
      let year = planList.lastSetYear
      planList.forEach((item, index) => {
        if (index != 0 && item.lastSetYear === year) {
          item.lastSetYear = ''
        } else {
          year = item.lastSetYear
        }
      })
      this.repaymentList = planList
    },
  },
}
</script>
<style lang="less" scoped>
.content-border {
  width: 0.26rem;
  height: 2.12rem;
  border: 0.01rem solid #cdcdcd;
  border-left: none;
  border-bottom: none;
  margin-left: 0.08rem;
}
.popup-content {
  width: 84%;
  height: 60vh;
  border-radius: 5px;
  padding: 0.4rem;

  .popup-title {
    display: flex;
    justify-content: space-around;
    font-size: 0.38rem /* 19/50 */;
    line-height: 0.52rem /* 26/50 */;
    font-weight: 500;
  }
  .popup-head {
    box-sizing: border-box;
    background-color: #4d7bfe;
    border-radius: 5px;
    color: #fff;
    margin: 0.4rem 0 0.6rem /* 20/50 */;
    padding-top: 0.4rem /* 20/50 */;
  }
}
.repayment-year {
  margin-top: -0.4rem;
}
.repayment-day {
  margin-top: -0.14rem;
}
.repayment-desciption {
  margin-left: 0.28rem;
}
.repayment-status {
  display: inline-block;
  padding: 0.06rem;
  border-radius: 0.04rem;
  margin-left: 0.16rem;
}
.repayment-type {
  display: inline-block;
  border-left: 1px solid #303133;
  margin: 0 0.1rem 0 0.1rem;
  padding-left: 0.1rem;
}
.returned {
  background: #f5f5f5;
  color: #b7bbbf;
}
.overdue {
  background: #ffdfdf;
  color: #ff6646;
}
</style>
