<template>
  <div>
    <van-overlay
      :show="isShow"
      @click="isShow = false"
      :custom-style="{
        background: 'rgb(255, 255, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
      }"
    >
      <div class="wrapper">
        <div class="block">
          <van-loading type="spinner" color="#ffffff" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Vue from 'vue'
import { Overlay, Loading } from 'vant'

Vue.use(Overlay)
Vue.use(Loading)
export default {
  data() {
    return {
      isShow: true,
    }
  },
  created() {},
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.4rem;
  /deep/ .van-loading,
  /deep/ .van-loading__text {
    color: #fff;
  }
}
</style>
